import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import "./style/reset.css"

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import anchorFunc from "@/components/anchor"; //锚点使用
import "@/assets/icons"; // 导入图标资源
import SvgIcon from "@/components/SvgIcon.vue"; // svg component
import "./style/public.css"
// import 'lib-flexible/flexible'
import './utils/flexible'
import './style/wangeditor.css'
// 1. 全局注册SvgIcon组件
Vue.component('svg-icon', SvgIcon)

Vue.config.productionTip = false
Vue.directive('anchor', anchorFunc);
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')